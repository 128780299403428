<i18n>
{
  "en": {
    "browser_title": "Girls",
    "actress_list": "Girls"
  },
  "ja": {
    "browser_title": "女のコ一覧",
    "actress_list": "女のコ一覧"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <div id="actress" class="page-margin">
      <div class="container">
        <div class="heading is-page-heading">
          <h1>{{ $t('actress_list') }}</h1>
        </div>

        <!-- japanese tabs -->
        <vue-tabs v-if="locale == 'ja'" type="pills" class="is-scrollable" v-model="activeTab" @tab-change="handleTabChange">
          <v-tab title="あ行">
            <actress-list character="あ" character-sub="あ" />
            <actress-list character="あ" character-sub="い" />
            <actress-list character="あ" character-sub="う" />
            <actress-list character="あ" character-sub="え" />
            <actress-list character="あ" character-sub="お" />
          </v-tab>
          <v-tab title="か行">
            <actress-list character="か" character-sub="か" />
            <actress-list character="か" character-sub="き" />
            <actress-list character="か" character-sub="く" />
            <actress-list character="か" character-sub="け" />
            <actress-list character="か" character-sub="こ" />
          </v-tab>
          <v-tab title="さ行">
            <actress-list character="さ" character-sub="さ" />
            <actress-list character="さ" character-sub="し" />
            <actress-list character="さ" character-sub="す" />
            <actress-list character="さ" character-sub="せ" />
            <actress-list character="さ" character-sub="そ" />
          </v-tab>
          <v-tab title="た行">
            <actress-list character="た" character-sub="た" />
            <actress-list character="た" character-sub="ち" />
            <actress-list character="た" character-sub="つ" />
            <actress-list character="た" character-sub="て" />
            <actress-list character="た" character-sub="と" />
          </v-tab>
          <v-tab title="な行">
            <actress-list character="な" character-sub="な" />
            <actress-list character="な" character-sub="に" />
            <actress-list character="な" character-sub="の" />
          </v-tab>
          <v-tab title="は行">
            <actress-list character="は" character-sub="は" />
            <actress-list character="は" character-sub="ひ" />
            <actress-list character="は" character-sub="ふ" />
            <actress-list character="は" character-sub="ほ" />
          </v-tab>
          <v-tab title="ま行">
            <actress-list character="ま" character-sub="ま" />
            <actress-list character="ま" character-sub="み" />
            <actress-list character="ま" character-sub="む" />
            <actress-list character="ま" character-sub="め" />
            <actress-list character="ま" character-sub="も" />
          </v-tab>
          <v-tab title="や行">
            <actress-list character="や" character-sub="や" />
            <actress-list character="や" character-sub="ゆ" />
            <actress-list character="や" character-sub="よ" />
          </v-tab>
          <v-tab title="ら行">
            <actress-list character="ら" character-sub="ら" />
            <actress-list character="ら" character-sub="り" />
            <actress-list character="ら" character-sub="る" />
            <actress-list character="ら" character-sub="れ" />
          </v-tab>
          <v-tab title="わ行">
            <actress-list character="わ" character-sub="わ" />
          </v-tab>
        </vue-tabs>

        <!-- english tabs -->
        <vue-tabs v-if="locale == 'en'" type="pills" class="is-scrollable" v-model="activeTab" @tab-change="handleTabChange">
          <v-tab v-for="(item, index) in actressEnList" :key="index" :title="index">
            <actress-list :character="index" :character-sub="index" />
          </v-tab>
        </vue-tabs>
      </div>
    </div><!-- /#actress -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import { VueTabs, VTab } from 'vue-nav-tabs';
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import ActressList from '@/components/actress/actressList.vue';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'actress-list': ActressList,
  },
  data() {
    return {
      dynamicTitle: '',
      actressEnList: [],
      activeTab: (this.$route.hash) ? decodeURIComponent(this.$route.hash).substr(1) : 'あ行',
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();
    this.actressEnList = await bfAPI.getActressEnList();
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    if (this.$route.hash) {
      this.activeTab = decodeURIComponent(this.$route.hash).substr(1); // remove the hash character
    }

    // one-time emit to get the contents of the 1st tab
    EventBus.$emit('actress:tab:active', this.activeTab);
  },
  methods: {
    handleTabChange(tabIndex, newTab) {
      EventBus.$emit('actress:tab:active', newTab.title);

      // doing a sneaky thing here and using the history API to add a hash anchor instead of using
      // $router.replace() since that seems to trigger a route change, which is what we don't want.
      // btw, using replaceState instead of pushState so that the tab changes don't annoyingly get
      // saved in the browser history
      window.history.replaceState({}, null, `${this.$route.path}#${encodeURIComponent(newTab.title)}`);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_actress.scss';
</style>
