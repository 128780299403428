<i18n>
{
  "en": {
    "actress_header": ""
  },
  "ja": {
    "actress_header": "で始まる女のコ"
  }
}
</i18n>

<template>
  <div class="section" v-if="actressList.length">
    <div class="heading" v-if="locale == 'ja'">
      <h2>「{{ characterSub }}」{{ $t('actress_header') }} <span class="search-count">({{ actressList.length }}人)</span></h2>
    </div>

    <div class="grid is-actress">
      <div class="grid-item" v-for="item in actressList" :key="item.id">
        <router-link :to="`/search/?a=${item.id}`" class="entry">
          <div class="entry-media">
            <div class="media-thum">
              <img :src="`/dyn/dla/images/actresses/portraits/${item.id}/paco__@200.jpg`" @error="imgPlaceholder" class="media-image" loading="lazy" v-sfw />
            </div>
          </div>
          <div class="entry-meta">
            <div class="meta-name">{{ item.name }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */

import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    character: {
      type: String,
    },
    characterSub: {
      type: String,
    },
  },

  data() {
    return {
      actressList: [],
    };
  },

  async created() {
    const bfAPI = new BifrostAPI();
    let actresses = [];

    // set listener for emit event from the parent tab component
    EventBus.$on('actress:tab:active', async (title) => {
      // get list if the title matches the 1st character and hasn't been fetched yet
      if (title.substr(0, 1) === this.character && this.actressList.length === 0) {
        if (this.locale === 'ja') {
          actresses = await bfAPI.getActressList();
        } else if (this.locale === 'en') {
          actresses = await bfAPI.getActressEnList();
        }
        if (Object.prototype.hasOwnProperty.call(actresses[this.character], this.characterSub)) {
          this.actressList = actresses[this.character][this.characterSub];
        }
      }
    });
  },

  methods: {
    imgPlaceholder(e) {
      e.target.src = '/img/common/actress_placeholder.png';
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
